import { User, UserRole } from '../models/user';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InitializeAction, LoginAction, LogoutAction, RegisterAction } from '../redux/actions/authAction';
import axios from 'src/utils/axios';
import { verify, JWT_SECRET } from 'src/utils/jwt';
import type { AppThunk } from '../redux/store';
import { useSelector } from '../redux/store';
import { ListItemButton } from '@mui/material';

interface EventState {
    loading: boolean;
    idSelected: number;
    page: number;
    limit: number;
    total: number;
    tabSelected: number | 'all'
}

export type PageEventAction = {
    type: 'SET_PAGE_EVENT';
    payload: {
        page: number;
    };
};

export type LimitEventAction = {
    type: 'SET_LIMIT_EVENT';
    payload: {
        limit: number;
    };
};

export type TotalEventAction = {
    type: 'SET_TOTAL_EVENT';
    payload: {
        total: number;
    };
};

export type LoadingAction = {
    type: 'LOADING_EVENT';
    payload: {
        loading: boolean;
    };
};

export const initialState: EventState = {
    loading: false,
    idSelected: 0,
    page: 0,
    limit: 10000,
    total: 0,
    tabSelected: 'all'
};

const slice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        SET_PAGE_EVENT: (state: EventState, action: PageEventAction): EventState => {
            const { page } = action.payload;
            return {
                ...state,
                page
            };
        },
        SET_LIMIT_EVENT: (state: EventState, action: LimitEventAction): EventState => {
            const { limit } = action.payload;
            return {
                ...state,
                limit
            };
        },
        SET_TOTAL_EVENT: (state: EventState, action: TotalEventAction): EventState => {
            const { total } = action.payload;
            return {
                ...state,
                total
            };
        },
        LOADING_EVENT: (state: EventState, action: LoadingAction): EventState => {
            const { loading } = action.payload;
            return {
                ...state,
                loading
            };
        },
    }
});

export const reducer = slice.reducer;

export const getListAllEvents = async (page: number, limit: number): Promise<any> => {
    let output = null
    try {
        const response = await axios.get(`/api/ticket/list/me?page=${page}&size=${limit}`);
        output = response.data.payload;
    } catch (err) {
        console.error('listTicketsException', err);
    }

    return output;
}

export const getListAllEventsOperator = async (idProfile: number, page: number, limit: number): Promise<any> => {
    let output = null
    try {
        const response = await axios.get(`/api/ticket/list/operator/${idProfile}?page=${page}&size=${limit}`);
        output = response.data.payload;
    } catch (err) {
        output = null;
        console.error('listTicketsOperatorException', err);
    }

    return output;
}

export const getListAllEventsAdmin = async (page: number, limit: number): Promise<any> => {
    let output = null
    try {
        const response = await axios.get(`/api/ticket/list/allTicket`);
        output = response.data.payload;
    } catch (err) {
        console.error('listTicketsAdminException', err);
    }

    return output;
}

export const getListNotificationsByProfile = async (idProfile: number, page: number, limit: number): Promise<any> => {
    let output = null
    try {
        const response = await axios.get(`/api/notification/profile/${idProfile}?page=${page}&size=${limit}`);
        output = response.data.payload;
    } catch (err) {
        console.error('listNotificationsException', err);
    }

    return output;
}

export const downloadReport = async (idProfile: number, month: number, year: number): Promise<any> => {
    let output = null
    try {
        const response = await axios.get(`/api/report/${idProfile}?month=${month}&year=${year}`,{
            responseType: 'blob',
        });
        output = response.data;
    } catch (err) {
        console.error('downloadReportException', err);
    }

    return output;
}

export const getListAllComments = async (idTicket: number, page: number, limit: number): Promise<any> => {
    let output = null
    try {
        const response = await axios.get(`/api/ticket/${idTicket}/comment?page=${page}&size=${limit}`);
        output = response.data.payload;
    } catch (err) {
        console.error('listTicketsCommentsOperatorException', err);
    }

    return output;
}

export const getListCategories = async (): Promise<any> => {
    let output = null

    try {
        const response = await axios.get('/api/ticket/category');
        output = response.data.payload;

    } catch (err) {
        console.error('listCategoriesException', err);
    }


    return output;
}

export const getListOperators = async (): Promise<any> => {
    let output = null

    try {
        const response = await axios.get('/api/profile/operator');
        output = response.data.payload;

    } catch (err) {
        console.error('listCategoriesException', err);
    }


    return output;
}

export const updateEvent = async (data: any): Promise<any> => {
    const response = await axios.put('/api/ticket/' + parseInt(data.id), {
        "description": data.description,
        "kilometer": data.kilometer,
        "sector": data.sector,
        "commune": data.commune,
        "orientation": data.orientation,
        "dateEvent": data.dateEvent,
        "classificationId": data.classificationId,
        "statusCode": data.statusCode,
        "assignedToId": data.assignedToId,
        "realAddress": data.realAddress,
        "email": data.email,
        "fullName": data.fullName,
        "phone": data.phone,
        "rut": data.rut
    });

    return response;
}

export const changeStatusEvent = async (id: number, data: any, statusCode: string): Promise<any> => {
    const response = await axios.put(`/api/ticket/${id}`, {
        "statusCode": statusCode,
    });

    return response;
}

export const createComment = async (id: number, comment: string): Promise<any> => {
    const response = await axios.post(`/api/ticket/${id}/comment`, {
        "comment": comment,
    });

    return response;
}

export const getAttachmentEvent = async (id:BigInteger) => {
    let output = null

    try {
        const response = await axios.get(`api/ticket/${id}/attachment?page=0&size=100`);
        output = response.data.payload;

    } catch (err) {
        console.error('listCategoriesException', err);
    }


    return output;
}

export const getStatistic = async (): Promise<any> => {
    let output = null

    try {
        const response = await axios.get('/api/report/reportStatistic');

        output = response.data;

    } catch (err) {
        console.error('StatisticEventsError', err);
    }


    return output;
}

export const setLimit =
    (limit: number): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(slice.actions.SET_LIMIT_EVENT({
                limit: limit
            }))
        };

export const setPage =
    (page: number): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(slice.actions.SET_PAGE_EVENT({
                page: page
            }))
        };

export const setLoading =
    (flag: boolean): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(slice.actions.LOADING_EVENT({
                loading: flag
            }))
        };

export default slice;